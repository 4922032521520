<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <div class="wbg">
          <h1>Prestamos para </h1>
          <h1>todos!</h1>
          <h4>¡Si necesitan un préstamo con urgencia,</h4>
          <h4>si quieren un préstamo más sólido, si </h4>
          <h4>desean un préstamo con monto más alto, </h4>
          <h4>utiliza RápidoPrésta!</h4>
        </div>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div class="inner inner2">
      <h3>Nuestro principio de servicio</h3>
      <div class="inner2_item flex jc-sb">
        <div v-for="(item,index) in inner_2items" :key="index" class="content" :style="{'background-image': `url(${item.img})`}">
          <!-- <img class="bg" :src="item.img" alt=""> -->
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 ? item.txt2 : `&nbsp;` }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="selector1" class="inner inner3">
      <h3>Características exclusivas de nuestra aplicación</h3>
      <p>Desembolso rápido, tarifas claras, sin tarifas ocultas, servicio las 24 horas para usted</p>
      <div class="inner3_item flex jc-sb fw-w">
        <div v-for="(item,index) in leftItemList" :key="index" class="content">
          <img class="bg" :src="item.img" alt="">
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 ? item.txt2 : `&nbsp;` }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="selector2" class="inner inner4">
      <h3>Reciba su efectivo rápidamente en 5 pasos</h3>
      <div class="flex al-c jc-c">
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in inner_4items" :key="item.id" class="carousel-item">
                <p class="title">{{ item.id }}</p>
                <img :src="item.img">
                <h1>{{ item.txt }}</h1>
                <p>{{ item.txt2 }}</p>
                <img v-if="Number(item.id) !== inner_4items.length" class="right" src="@/assets/images/xiangyou@2x.png">
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner5">
      <h3>Queremos ser el puente hacia tu libertad financiera</h3>
      <div class="flex al-c jc-c">
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in visibleItems" v-show="Number(item.id) !== 5" :key="item.id" class="carousel-item flex fd-c jc-c">
                <img class="tx" :src="item.img">
                <h1>{{ item.name }}</h1>
                <h2>{{ item.txt }}</h2>
                <div class="flex stars">
                  <img v-for="index in 5" :key="index" src="@/assets/images/loanImg/star.png">
                </div>
                <div>
                  <p>{{ item.txt2 }}</p>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <button class="carousel-button" :disabled="currentIndex >= items.length - 3" @click="nextSlide"><img src="@/assets/images/loanImg/black_right.png"></button>
      <button class="carousel-button" :disabled="currentIndex === 0" @click="previousSlide"><img src="@/assets/images/loanImg/black_left.png"></button>
    </div>
    <div id="selector3" class="inner inner6">
      <!-- <h4>Ventajas</h4> -->
      <div class="content">
        <h1>SOBRE NOSOTROS </h1>
        <div class="left_cell">
          <p class="inner1_text">RápidoPrésta, es un plataforma tecnología financiera de vanguardia , diseñado y desarrollado para facilitar
            rápidamente las necesidades de financiación personal de nuestros clientes. RápidoPrésta permite a los clientes
            acceder rápidamente a préstamos en tan solo unos minutos, sin papeleos ni barreras.<br>
            Ofrecemos una variedad de esquemas de préstamos que se adaptan a las diferentes necesidades y circunstancias
            de nuestros clientes.Kina se compromete a proporcionar el mejor servicio y apoyo posible a nuestros clientes para
            que sientan que nos preocupamos por ellos y nos preocupamos por ellos.<br>
            El proceso de solicitud, aprobación y desembolso del préstamo es totalmente automatizado, rápido y sin esfuerzo.
          </p>
        </div>
      </div>
    </div>
    <div class="inner inner7">
      <div class="title">
        <h3>Contáctanos</h3>
      </div>
      <div class="flex items jc-sb">
        <div v-for="(item,index) in inner7items" :key="index" class="content" :style="{'background-image': `url(${item.img2})`}">
          <div class="flex ai-c">
            <div>
              <h1>{{ item.txt }}</h1>
              <p>{{ item.txt2 }}</p>
            </div>
            <img :src="item.img">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftItemList: [
        { txt: 'Verificación de legibilidad de \n 10 minutos', txt2: 'El proceso de aprobación se realizará automáticamente a \n través de nuestro sistema avanzado en muy poco tiempo.', img: require('@/assets/images/itemList_01.png'), img2: require('@/assets/images/itemList_04.png') },
        { txt: 'Fondos en la cuenta dentro de \n las 24 horas', txt2: 'En cualquier momento solicitado, nuestro sistema procederá y \n ofrecerá 24 horas servicio de transferencia de dinero.', img: require('@/assets/images/itemList_02.png'), img2: require('@/assets/images/itemList_05.png') },
        { txt: 'Préstamo fácil y \n conveniente', txt2: 'Solicite con su teléfono Android y credencial de identidad en \n 5 minutos. Dinero instantáneo para sus necesidades inmediatas.', img: require('@/assets/images/itemList_03.png'), img2: require('@/assets/images/itemList_06.png') },
        { txt: 'Las tarifas son claras y no hay \n pago por adelantado', txt2: 'La aplicación de RápidoPrésta le permite conocer las tarifas \n detalladas sin ningún tipo de ocultamiento de cargos.', img: require('@/assets/images/itemList_04.png'), img2: require('@/assets/images/itemList_06.png') }
      ],
      leftItemList2: [
        { title: 'What can you get?', list2: [{ img: require('@/assets/images/list01.png'), txt: 'From 1,000 ₱ up to 50,000 ₱' }, { img: require('@/assets/images/list02.png'), txt: 'Repayment Term：0-180 days' }, { img: require('@/assets/images/list03.png'), txt: 'Annual interest rate：10%-35%' }] },
        { title: 'Who is eligible to get a loan?', list2: [{ img: require('@/assets/images/list04.png'), txt: 'Age 18-60 years' }, { img: require('@/assets/images/list05.png'), txt: 'Philippines citizen' }, { img: require('@/assets/images/list06.png'), txt: 'Employed or Business-owner' }] }
      ],
      inner_2items: [
        { txt: 'No revisamos tu historial ni buró ', txt2: 'Nuestra tecnología nos permite determinar \n la posibilidad de otorgarte un préstamo sin \n consultar tu historial crediticio.', img: require('@/assets/images/itemList_05.png') },
        { txt: 'Política Cero Acoso', txt2: 'No contamos con ningún tipo de práctica \n de cobranza abusiva o agresiva. Los datos \n que otorgas en tu solicitud jamás serán \n utilizados para acosarte. Confiamos en ti.', img: require('@/assets/images/itemList_06.png') },
        { txt: 'Beneficios financieros', txt2: 'Queremos ayudarte a crecer, por eso \n desarrollamos programas que premian \n tu constancia de pago, mejoran tu historial \n y que te ayudan a ganar dinero.', img: require('@/assets/images/itemList_07.png') }
      ],
      items: [
        // 您的轮播内容数据
        { id: '1', name: 'Omar', txt: 'Líder de línea', txt2: '“Considero que RápidoPrésta te ofrece préstamos \n con la mejor tasa de interés en comparación con \n otras aplicaciones similares, mi experiencia ha sido \n de lo mejor.”', img: require('@/assets/images/Product01.png') },
        { id: '2', name: 'Ladyce', txt: 'Asistente', txt2: '“¡Recomendado! Y el tiempo que da para pagar \n es muy efectivo a comparación de otras app que \n solo te dan 7 a 10 días.. RápidoPrésta me dió un \n mes para pagar.. Gracias!!.”', img: require('@/assets/images/Product02.png') },
        { id: '3', name: 'Arii', txt: 'Repartidor', txt2: '“Hasta este momento me parece que \n que mejores beneficios te brinda, \n los intereses son bajo y puedes \n acumulas puntos y esta super bien.”', img: require('@/assets/images/Product03.png') },
        { id: '4', name: 'Alhertina Rayon', txt: 'Empresarios', txt2: '“Me gusta RápidoPrésta porque es muy rápido en \n darme el dinero que necesito y si tienes alguna \n duda se comunican inmediatamente. Por supuesto, \n lo recomiendo.”', img: require('@/assets/images/Product04.png') },
        { id: '5', name: '', txt: '', txt2: '' }
      ],
      visibleItemCount: 3,
      currentIndex: 0,
      inner_4items: [
        // 您的轮播内容数据
        { id: '1', name: 'Lando Wolf', txt: 'Descarga la App', txt2: 'Vaya a GooglePlay para \n descargar RápidoPrésta', img: require('@/assets/images/icon-1.png') },
        { id: '2', name: 'Alice Ura', txt: 'Registrarse y verificar', txt2: 'Debe registrarse y completar \n la información requerida para \n terminar la verificación.', img: require('@/assets/images/icon-2.png') },
        { id: '3', name: 'Marino Zavaleta', txt: 'Completar la solicitud', txt2: 'Tómese unos minutos para \n proporcionar los conceptos \n básicos y evaluaremos el \n monto del préstamo que \n podemos ofrecerle', img: require('@/assets/images/icon-3.png') },
        { id: '4', name: 'Alhertina Rayon', txt: 'Recibe tu dinero en \n  efectivo', txt2: 'Si tu solicitud fue autorizada, \n realizaremos la transferencia \n a tu cuenta en minutos', img: require('@/assets/images/icon-4.png') },
        { id: '5', name: 'Alhertina Rayon', txt: 'Pagar el préstamo a \n tiempo', txt2: 'Cada préstamo se puede \n pagar en varias cuotas. \n Completa el pago de acuerdo \n al plan, tendrás la oportunidad \n de recibir múltiples montos al \n mismo tiempo', img: require('@/assets/images/icon-5.png') }
      ],
      inner_3items: [
        { img: require('@/assets/images/list07.png'), txt: 'Puede elegir la cantidad y \n el límite de tiempo' },
        { img: require('@/assets/images/list10.png'), txt: 'Puede solicitar un préstamo \n en cualquier momento' },
        { img: require('@/assets/images/list08.png'), txt: 'Preste dinero todo el día los \n 365 días, no se preocupe por los \n fines de semana y feriados' },
        { img: require('@/assets/images/list11.png'), txt: 'Solo necesitas enviar una solicitud, \n te atenderemos durante todo el \n proceso' }
      ],
      inner7items: [
        { img: require('@/assets/images/loanImg/address.png'), img2: require('@/assets/images/loanImg/address_bg.png'), txt: 'Ubicación', txt2: 'Solidaridad 16,Guadalupe,NLE, \n 67189,Mexico' },
        { img: require('@/assets/images/loanImg/email.png'), img2: require('@/assets/images/loanImg/email_bg.png'), txt: 'Correo electrónico', txt2: 'rapidopresta@outlook.com \n ' },
        { img: require('@/assets/images/loanImg/time.png'), img2: require('@/assets/images/loanImg/time_bg.png'), txt: 'Horas de servicio', txt2: 'De lunes a viernes 9:00-16:00 \n ' }
      ]
    }
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.currentIndex, this.currentIndex + this.visibleItemCount)
    }
  },
  created() {
    // setInterval(() => {
    //   this.nextSlide()
    // }, 4)
  },
  methods: {
    inner_2Class(index) {
      if (index === 4) {
        return 'content2'
      } else if (index % 2 !== 0) {
        return 'text_r'
      }
    },

    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=rapido.presta.cash', '_blank')
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - this.visibleItemCount) {
        this.currentIndex++
      } else {
        this.currentIndex--
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top{
  min-width: 1200px;
  background: #fff;
  white-space: pre-line;
  .inner{
    width: 100%;
    padding:60px calc((100% - 1100px) / 2);
    margin: 0 auto;
  }
  .inner1{
    // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
    padding:0px calc((100% - 1100px) / 2);
    background: url("~@/assets/images/loanImg/inner1_bg.png") #DBC6AB no-repeat right;
    background-size: 55% auto;
    height: 430px;
    display: flex;
    justify-content: space-between;
    .left_item{
      box-sizing: border-box;
      border-radius: 10px;
      padding-top: 50px;
      h1{
        font-size: 35px;
        font-weight: 700;
        // margin-bottom: 10px;
        line-height: 50px;
        span{
          color: #00BB53;
        }
      }
      h4{
        font-size: 16px;
        line-height: 30px;
        font-weight: normal;
        // margin: 0px 0;
      }
      & h4:nth-of-type(1){
        margin-top: 20px;
      }
      .item_info{
        margin: 20px 0;
        display: flex;
        // height: 170px;
        .info_img1{
          width: 25px;
        }
        .info_tips{
          display: flex;
          flex-direction: column;
          p{
            flex: 1;
            display: flex;
            box-sizing: border-box;
            // margin-left: 10px;
            font-size: 16px;
            color: #FFFFFF;
            align-items: center;
            margin-bottom: 10px;
            line-height: 30px;
            img{
              margin-right: 10px;
              margin-bottom: 25px;
            }
          }
        }
        .info_img2{
          width: 135px;
          height: 135px;
        }
      }
      .item_download{
        // padding-left: 22px;
        cursor: pointer;
        margin-top: 30px;
        color: white;
        border-radius: 5px;
        img{
          width: 160px;
          height: 50px;
          margin-right: 5px;
        }
      }
    }
    .right_item{
      img{
        height: 376px;
        margin-right: 30px;
      }
    }
  }
  .inner2{
    position: relative;
    padding:90px calc((100% - 1100px) / 2) 80px;
    // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    h3{
      font-size: 32px;
      text-align: left;
      margin-bottom: 60px;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      // background: #FFFFFF;
      // border: 1px solid #E2E2E2;
      background-size: 100% 100%;
      width: 29%;
      text-align: left;
      color:black;
      padding: 75px 20px 30px;
      // border-radius: 5px;
      .item_info{
        margin: 30px 0px 0;
        background: #FFFFFF;
        padding: 20px 15px;
        opacity: 0.9;
        height: 85%;
      }
      .bg{
        width:70px;
        margin: 0 auto;
        display: block;
      }
      .bg2{
        display: block;
        width: 45%;
        margin: -90px auto 0;
      }
      h1{
        font-size:14px;
        // line-height: 50px;
        margin:0 0 15px 0;
      }
      p{
        color: #666666;
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
      }
    }
  }
  .inner3{
    position: relative;
    padding:50px calc((100% - 1100px) / 2) 60px;
    // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    h3{
      font-size: 32px;
      text-align: left;
      margin-bottom: 20px;
    }
    p{
      font-size: 15px !important;
      color: #666666;
    }
    .inner3_item{
      margin-top: 40px;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      width: 45%;
      text-align: left;
      color:black;
      padding: 0 0 30px 0;
      // border-radius: 20px;
      margin-bottom: 15px;
      .item_info{
        margin: 20px 0px 0;
      }
      .bg{
        width: 100%;
      }
      .bg2{
        display: block;
        width: 45%;
        margin: -90px auto 0;
      }
      h1{
        font-size: 25px;
        // line-height: 50px;
        margin:0 0 15px 0;
        line-height: 35px;
      }
      p{
        line-height: 20px;
        font-weight: bold;
      }
    }
  }
  .inner4{
      background: #F5F5F5;
      padding: 70px calc((100% - 1170px) / 2) 40px;
      h3{
        font-size: 24px;
        color: black;
        text-align: left;
        margin-bottom: 60px;
        position: relative;
        padding-left: 20px;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
      p{
        padding: 10px 20px;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
      .carousel-container {
        position: relative;
        width: 100%;
        height: 400px;
      }
      .carousel {
        display: flex;
        overflow: hidden;
        height: 100%;
      }
      .carousel-wrapper {
        display: flex;
        width: 100%;
        justify-content:space-around;
        align-items: center;
      }
      .carousel-item {
        width: 19.1%;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        background: url("~@/assets/images/content_bg.png") no-repeat center;
        background-size: 120% 100%;
        color: #fff;
        border-radius: 5px;
        font-size: 18px;
        padding: 30px 0 0;
        position: relative;
        // margin:  0 10px;
        .title{
          color: #fff;
          font-size: 20px;
          font-weight: bold;
        }
        h1{
          color: black;
          font-size: 15px;
          line-height: 20px;
          margin-top: 25px;
          text-align: left;
          height: 35px;
          padding: 0 20px;
        }
        p{
          font-size: 12px;
          height: 80px;
        }
        img{
          width: 40px;
        }
        .right{
          position: absolute;
          top: calc(50% - 20px);
          right: -19px;
          width: 35px;
        }
      }
      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.2s, transform 0.2s;
      }

      .fade-enter,
      .fade-leave-to {
        opacity: 0;
        transform: translateX(300px);
      }
    }
  .inner5{
    background: #FFFFFF;
    padding: 50px calc((100% - 1160px) / 2) 80px;
    h3{
      font-size: 30px;
      text-align: left;
      // margin-bottom: 20px;
      position: relative;
      margin-bottom: 60px;
      padding-left: 20px;
      // &::after{
      //   display: block;
      //   content:'';
      //   width: 80px;
      //   height: 5px;
      //   background: #00BB53;
      //   margin: 20px auto 0;
      // }
    }
    p{
      padding: 10px 0px 0;
      font-size: 12px;
      color: #666666;
      text-align: left;
      line-height: 18px;
    }
    .tx{
      position: absolute;
      top: -10px;

    }
    .carousel-container {
      position: relative;
      width: 100%;
      height: 300px;
    }
    .carousel {
      display: flex;
      overflow: hidden;
      height: 100%;
    }
    .carousel-wrapper {
      display: flex;
      // width: 100%;
      // justify-content:space-around;
      align-items: center;
      // padding-left: 200px;
    }
    .carousel-item {
      // min-width: 40% !important;
      width: 400px !important;
      height: 220px;
      display: flex;
      align-items: center;
      background: #F5F5F5;
      color: #fff;
      border-radius: 5px;
      font-size: 18px;
      padding: 50px 15px 10px;
      margin: 0 20px;
      .stars{
        img{
          width: 12px;
          height: 12px;
          margin-right: 2px;
        }
      }
      h1{
        color: black;
        font-size: 16px;
        // line-height: 25px;
        text-align:  center;
        font-weight: bold;
      }
      h2{
        font-size: 12px;
        color: #666666;
        font-weight: 500;
        line-height: 30px;
      }
      img{
        width: 80px;
        height: 80px;
      }
      p{
        height: 110px;
      }
    }
    .carousel-button {
      // position: absolute;
      // padding: 20px;
      font-size: 20px;
      border: none;
      background-color: transparent;
      color: #555;
      outline: none;
      cursor: pointer;
      img{
        width: 40px;
      }
      float: right;
    }
    .carousel-button:nth-of-type(2){
      margin-right: 20px;
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.2s, transform 0.2s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
      transform: translateX(400px);
    }
  }
  .inner6{
    background: #F5F5F5;
    .content{
      display: flex;
      flex-direction: column;
      h1{
        text-align: center;
        margin-top: 30px;
      }
      .inner1_text{
        font-size: 13px;
        color: #333;
        line-height: 24px;
        margin: 25px 0 40px;
        padding: 0 110px;
      }
      .left_cell,.right_cell{
        flex: 1;
        // display: flex;
        // justify-content: center;
        // padding: 0 0 60px;s
      }
      .right_cell{
        img{
          margin: 25px auto !important;
          width: 380px;
          height: 380px;
          display: block;
        }
      }
    }
  }
  .inner7{
    position: relative;
    padding:100px calc((100% - 1100px) / 2) ;
    background: #FFFFFF;
    white-space: pre-line;
    .title{
      margin-bottom: 50px;
      padding-left: 10px;
      h3{
        font-size: 30px;
        color: #333333;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
    }
    .content{
      width: 30%;
      margin: 0 10px;
      padding: 160px 15px 20px;
      background-size: 100% 100%;
      .flex{
        background: #fff;
        opacity: 0.9;
        padding: 15px 15px;
        justify-content: space-between;
        height: 110px;
      }
      img{
        width: 30px;
      }
      h1{
        font-size: 18px;
        line-height: 35px;
      }
      p{
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;
      height: 350px;
      position: relative;
      background-size: 100% auto;
      background: #DBC6AB;
      .left_item{
        // margin: 0px 10px 10px;
        padding: 30px 0px 0 0 !important;
        flex: 1;
        h1{
          font-size: 30px;
          line-height: 40px;
          // line-height: 50px;
          padding-left: 20px;
        }
        h4{
          font-size: 12px;
          margin: 0 !important;
          padding-left: 20px;
        }
        & h4:nth-of-type(1){
          margin-top: 60px;
        }
        .item_download {
          margin-top: 30px;
          padding-left: 0 !important;
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
        .wbg{
          // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
        }
        .item_info{
          background: #063EA4;
          padding-left: 10px;
          margin: 30px 0;
          justify-content: center;
          .info_tips{
            p{
              font-size: 12px;
            }
            img{
              width: 15px;
            }
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner2{
      padding:50px calc((100% - 1200px) / 2);
      h3{
        width: 100%;
        font-size: 20px;
        text-align: center;
      }
      .inner2_item{
        flex-direction: column;
        width: 100%;
        .content{
          width: 80%;
          margin: 0 auto 30px;
        }
      }
    }
    .inner3{
      padding: 0px calc((100% - 1200px) / 2);
      .inner2_bg{
        background: none;
      }
      div{
        width: 100%;
        padding: 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 30px;
      }
      p{
        text-align: center;
        margin: 0 10px;
      }
      .inner2_item{
        flex-direction: column;
      }
      .content{
        width: 100%;
        // padding: 20px 0;
        margin-bottom: 50px;
        h1{
          margin: 10px 0 20px;
          font-size: 20px;
          text-align:  center !important;
        }
        .item_info{
          margin: 20px 0 0 0 !important;
        }
      }
      .text_r{
        text-align: left !important;
        p{
          text-align: left !important;
        }
      }
      .content2{
        text-align: left;
        p{
        text-align: left;
        }
      }
    }
    .inner4{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw) !important;
      margin: 0 auto !important;
      padding: 50px 0px;
      div{
        width: 100%;
      }
      .right{
        display: none;
      }
      p{
        padding: 10px 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .left_bg{
        width: 30px;
        height: 50px;
        top: 40px;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
      .carousel-container{
        height: 2200px;
      }
      .carousel-wrapper{
        padding: 0 20px;
        flex-wrap: wrap;
        .carousel-item{
          width: 45%;
          margin: 0 5px;
        }
      }
      .carousel-item{
        width: 100% !important;
        height: 450px;
        img{
          width: 60px;
        }
        h1{
          font-size: 13px;
          height: 40px;
          padding: 0;
          line-height: 16px;
        }
        p{
          font-size: 12px;
          height: 90px;
          padding: 0 5px;
          line-height: 18px;
        }
      }
    }
    .inner5{
      padding: 30px 20px 30px;
      position: relative;
      display: none;
      h3{
        text-align: center;
        margin-bottom: 30px;
        line-height: 30px;
      }
      .carousel-container{
        height: 320px;
      }
      .carousel-item{
        min-width: 150px !important;
        width: 45% !important;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        h1{
          font-size: 12px;
          padding: 0 !important;
        }
        img{
          margin-bottom: 10px;
        }
        p{
          display: none;
        }
      }
    }
  .inner6{
    h1{
      margin-top: 30px !important;
    }
    h4{
      padding: 0 20px;
    }
    .inner1_text{
      padding: 0 !important;
    }
    .content{
      padding: 0 20px !important;
      display: flex;
      flex-direction: column;
      .left_cell{
        padding: 0;
      }
      .cell_item{
        width: 100% !important;
        height: 100px;
      }
      img{
        margin-top: 0px;
        width: 300px !important;
        height: 300px !important;
        margin: 0 auto ;
      }
    }
  }
    .inner7{
      padding: 50px calc((100% - 1300px) / 2);
      flex-direction: column;
      .title{
        padding-left: 0;
        text-align: center;
      }
      .items{
        flex-direction: column;
        .content{
          width: 80vw;
          margin: 0 auto 20px;
        }
      }
    }
  }
}
</style>
